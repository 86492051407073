import React, { useReducer, useEffect } from 'react'

import { Box, Flex } from 'ui/bend/elements'
import { P } from 'ui/bend/typography'

import Input from './Input'
import Select from './Select'

const reducer = (state, action) => {
  const formattedDate = new Date(state.time)
  switch (action.type) {
    case 'focus': {
      return {
        ...state,
        focused: true,
        input: formattedDate
          .toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          })
          .split(' ')[0]
      }
    }
    case 'blur': {
      const match = /((?<hours>1[0-2]|0?[1-9]):(?<minutes>[0-5][0-9]))/.exec(
        state.input
      )

      if (match) {
        const { hours, minutes } = match.groups
        // Handle the case of the previous time being > 12 hours and not flip the meridian
        if (formattedDate.getHours() >= 12 && Number(hours) < 12) {
          formattedDate.setHours(Number(hours) + 12)
        } else {
          formattedDate.setHours(hours)
        }
        formattedDate.setMinutes(minutes)
        return {
          ...state,
          focused: false,
          time: formattedDate.getTime(),
          error: null
        }
      } else {
        return {
          ...state,
          focused: false,
          error:
            'invalid date: make sure to specify a date within the following format: HH:MM, and make sure hours is less than (or equal to) 12 and minutes is less than 60.'
        }
      }
    }
    case 'changeTime': {
      return { ...state, input: action.payload }
    }
    case 'changePeriod': {
      const hours = formattedDate.getHours()

      if (action.payload === 'AM') {
        formattedDate.setHours(hours >= 12 ? hours - 12 : hours)
      } else {
        formattedDate.setHours(hours < 12 ? hours + 12 : hours)
      }
      return { ...state, time: formattedDate.getTime() }
    }
    default:
      return state
  }
}

const TimePicker = ({ initialValue, setValue }) => {
  const [{ time, focused, input, error }, dispatch] = useReducer(reducer, {
    time: initialValue ? new Date(initialValue).getTime() : Date.now(),
    focused: false,
    input: (initialValue ? new Date(initialValue) : new Date())
      .toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      })
      .split(' ')[0]
  })
  const formattedTime = new Date(time)

  useEffect(() => {
    if (setValue && typeof setValue === 'function')
      setValue(new Date(time).toUTCString())
    // Don't add setValue to the deps array, as its reference isn't stable
    // and will trigger an infinite re-render here.
  }, [time])

  return (
    <>
      <Flex maxWidth='200px'>
        <Input
          label='Time'
          containerProps={{ marginRight: '8px' }}
          value={
            focused
              ? input
              : formattedTime
                  .toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                  })
                  .split(' ')[0]
          }
          onFocus={() => dispatch({ type: 'focus' })}
          onBlur={() => dispatch({ type: 'blur' })}
          onChange={(e) => {
            dispatch({ type: 'changeTime', payload: e.target.value })
          }}
        />
        <Box minWidth='96px'>
          <Select
            value={formattedTime.getHours() < 12 ? 'AM' : 'PM'}
            setValue={(value) =>
              dispatch({ type: 'changePeriod', payload: value })
            }
            items={[
              { value: 'AM', label: 'AM' },
              { value: 'PM', label: 'PM' }
            ]}
          />
        </Box>
      </Flex>
      {error && (
        <P color='errorDefault' mt='8px'>
          {error}
        </P>
      )}
    </>
  )
}

export default TimePicker
